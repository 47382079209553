import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    customGeneral: {
        minWidth: 110,
        padding: '6px 16px',
        fontWeight: '500',
        textTransform: 'capitalize',
        fontSize: 20,
        fontStyle: 'normal',
        border: '1px solid #B2AFAF',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'SF Pro Display',
        fontWeight: 500,
        borderRadius: '10px',
        '&:disabled': {
            background: '#979797 !important',
        }
    }
}))


const CustomButton = (props) => {
  const { label, disabled, width, onClick, endIcon, backgroundred, style, height } = props;
  const classes = useStyles();
  const sizeButtonWidth = width === 'full' ? '100%' : 'fit-content';

    return (
        <Button
            {...props}
            className={classes.customGeneral}
            style={{ width: sizeButtonWidth, height: 48, background: '#9DCD2A' }}
            disabled={disabled}
            disableElevation={true}
            disableRipple={false}
            onClick={onClick}
            variant='contained'
            endIcon={endIcon}
        >
            {label}
        </Button>
    );
}

export default CustomButton;
