import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import moment from 'moment';
import {
    makeStyles,
    CircularProgress,
    IconButton,
    Modal
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CreateRounded from '@material-ui/icons/CreateRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';

import { PatientTrackingContext } from "../PatientTrackingContext";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { appContext } from '../../../../../contexts/app';
import { PlanContext } from "../../PlanContext";

import CustomButton from '../../../../CustomButton';
import CustomRadioCheck from '../../../../CustomRadioCheck';
import CustomBannerPlan from '../../../../CustomBannerPlan';
import AddStage from './AddStage';
import EditStage from './EditStage';
import { IcArrowUp, IcArrowDown } from '../../../../IconSVG';

const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        // width: '90%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        padding: 30
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15
    },
    listStages: {
        borderTop: '1px solid #EDEEF0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 56,
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        marginLeft: 10,
        marginRight: 10,
    },
    backgroundStage: {
        height: 30,
        width: 30,
        borderRadius: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10
    }
});

function Stages() {
    const classes = useStyles();
    const { stages, loading, getPatientTracking, defaults } = useContext(PatientTrackingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    // ------------------------------------------
    const { getPlanById, planItem, loadingPlan } = useContext(PlanContext);
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    // ------------------------------------------

    const [addStagesOpen, setAddStagesOpen] = useState(false);
    const [editStagesOpen, setEditStagesOpen] = useState(false);
    const [deleteStage, setDeleteStage] = useState(false);
    const [loadingP, setLoadingP] = useState(false);
    const [editStage, setEditStage] = useState(false);
    const [orderedStageIds, setOrderedStageIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    useEffect(() => {
        if (stages) {
            setOrderedStageIds(getSortedStageIdsAlphabetical(stages));
        }
    }, [stages]);

    useEffect(() => {
        getPlanById();
    }, []);

    function moveStageUp(stageId) {
        const currentIndex = orderedStageIds.indexOf(stageId);
        if (currentIndex > 0) {
            orderedStageIds.splice(currentIndex, 1);
            orderedStageIds.splice(currentIndex - 1, 0, stageId);
            setOrderModified(true);
            setOrderedStageIds([...orderedStageIds]);
        }
    }

    function moveStageDown(stageId) {
        const currentIndex = orderedStageIds.indexOf(stageId);
        if (currentIndex < orderedStageIds.length - 1) {
            orderedStageIds.splice(currentIndex, 1);
            orderedStageIds.splice(currentIndex + 1, 0, stageId);
            setOrderedStageIds([...orderedStageIds]);
            setOrderModified(true);
        }
    }

    const submindeleteStage = (item) => () => {
        const stageId = item.stageId
        setLoadingP(true);
        insyncHttps.patientTracking
            .removeStageFromOffice({ officeId, stageId })
            .then(result => {
                if (result && result.data && result.data.status && result.data.status === 201) {
                    setLoadingP(false);
                    setDeleteStage(null);
                    error.setMessage(result.data.message);
                } else {
                    setLoadingP(false);
                    getPatientTracking();
                    getPlanById();
                }
            })
            .catch(err => {
                setLoadingP(false);
                error.setMessage(err.message);
            });
    }

    const changeDefault = (stageId) => () => {
        setLoadingP(true);
        insyncHttps.patientTracking
            .setDefaultStage({
                officeId,
                stageId
            })
            .then(result => {
                setLoadingP(false);
                setEditStage(false);
                getPatientTracking();
            })
            .catch(err => {
                setLoadingP(false);
                error.setMessage(err.message);
            });
    }

    const changeOrder = () => {
        setSaveOrderLoading(true);
        insyncHttps.patientTracking
            .reorderStages({
                officeId,
                stageIds: orderedStageIds
            })
            .then(result => {
                setSaveOrderLoading(false);
                getPatientTracking();
                setOrderModified(false)
            })
            .catch(err => {
                setSaveOrderLoading(false);
                getPatientTracking();
                setOrderModified(false)
            });
    }

    return (
        <div>
            {loadingPlan ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <div>
                    {!isEmpty(planItem) && (
                        <div>
                            {planItem.showBannerStages && (
                                <CustomBannerPlan
                                    boxBackground={'#F86F46'}
                                    boxText={'You have reached the maximum amount of stages in your plan. To add more click "upgrade plan"'}
                                    daysOff={false} // Boolean
                                    textDaysOff={''}
                                    colorDaysOff={''}
                                    btnText={'UPGRADE PLAN'}
                                    btnOnClick={`/office/${officeId}/plans`}
                                    btnTextColor={'#F86F46'}
                                />
                            )}
                            {planItem.showBannerTrial && (
                                <CustomBannerPlan
                                    boxBackground={'#197FE8'}
                                    boxText={`${planItem.planName}`}
                                    daysOff={true} // Boolean
                                    textDaysOff={`${moment(planItem.trialEnds).diff(currentDate, 'days')} days left`}
                                    colorDaysOff={'#358FEB'}
                                    btnText={'CHOOSE A PLAN'}
                                    btnOnClick={`/office/${officeId}/plans`}
                                    btnTextColor={'#197FE8'}
                                />
                            )}
                        </div>
                    )}
                </div>
            }
            <div className={classes.infoContainer}>
                <div className={classes.cardHeader}>
                    <p className={classes.title}> Stages </p>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: 105, display: 'flex', justifyContent: 'center' }} >
                                {saveOrderLoading ?
                                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                    :
                                    orderModified && (
                                        <div style={{ width: 80 }}>
                                            <CustomButton
                                                width='full'
                                                label='save'
                                                onClick={changeOrder}
                                                style={{ minWidth: 80, width: 90 }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div style={{ width: 80, display: 'flex', justifyContent: 'center', }}>
                            <IconButton
                                style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
                                onClick={() => {
                                    setAddStagesOpen(true);
                                }}
                            >
                                <AddRoundedIcon
                                    style={{ color: '#9DA2B4' }}
                                    fontSize='medium'
                                />
                            </IconButton>
                        </div>
                    </div>
                    <Modal
                        style={{ display: "flex" }}
                        open={addStagesOpen}
                        disableAutoFocus={true}
                    >
                        <AddStage
                            handleClose={() => {
                                setAddStagesOpen(false);
                                getPlanById();
                            }}
                        />
                    </Modal>
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                ) : (
                    <div>
                        {orderedStageIds.map(stageId => {
                            const stage = stages[stageId];
                            const isDefault = defaults && defaults.stageId ? defaults.stageId === stageId : false;

                            if (!stages[stageId]) {
                                return null;
                            }
                            return (
                                <div key={stageId}>
                                    {deleteStage && deleteStage.stageId === stageId ?
                                        <div
                                            className={classes.listStages}
                                            style={{ background: 'rgba(240, 100, 34, 0.12)' }}
                                        >
                                            <div style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    <p style={{ margin: 0, color: "#EC5829", marginLeft: 10 }}>
                                                        {stage.name}
                                                    </p>
                                                </div>
                                            </div>
                                            {loadingP ?
                                                <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                                :
                                                <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                    <div style={{ width: 127 }}>
                                                        <CustomButton
                                                            width='full'
                                                            label={'Delete'}
                                                            backgroundred={'#F86F46'}
                                                            onClick={submindeleteStage({ ...stage, stageId })}
                                                        />
                                                    </div>
                                                    <span
                                                        className={classes.subTitle}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setEditStage(null)
                                                            setDeleteStage(null)
                                                        }}
                                                    >
                                                        Cancel
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className={classes.listStages}>
                                            <p style={stage.hasOwnProperty('available') && !stage.available ? { pointerEvents: 'none', opacity: 0.4 } : {}}>{stage.name}</p>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className={classes.backgroundStage} style={{ background: stage.backgroundColor }}>
                                                    <p style={{ color: stage.textColor }}>T</p>
                                                </div>
                                                <p style={{ margin: 0, marginRight: 5 }}>
                                                    Initial Stage
                                                </p>
                                                <div style={stage.hasOwnProperty('available') && !stage.available ? { pointerEvents: 'none', opacity: 0.4, display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center' }}>
                                                    <CustomRadioCheck
                                                        checked={isDefault}
                                                        onChange={changeDefault(stageId)}
                                                    />
                                                    <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                                    <div style={{ width: 105, display: 'flex', justifyContent: 'center' }}>
                                                        <IconButton
                                                            style={{ height: 40, width: 40 }}
                                                            onClick={() => {
                                                                moveStageUp(stageId);
                                                            }}
                                                        >
                                                            <IcArrowUp width='7' height='15' fill='#9DA2B4' />
                                                        </IconButton>
                                                        <IconButton
                                                            style={{ height: 40, width: 40 }}
                                                            onClick={() => {
                                                                moveStageDown(stageId);
                                                            }}
                                                        >
                                                            <IcArrowDown width='7' height='15' fill='#9DA2B4' />
                                                        </IconButton>
                                                    </div>
                                                    <IconButton
                                                        style={{ height: 40, width: 40 }}
                                                        onClick={() => {
                                                            setEditStage({ ...stage, stageId });
                                                            setEditStagesOpen(true)
                                                            setDeleteStage(null);
                                                            setLoadingP(false)
                                                        }}
                                                    >
                                                        <CreateRounded style={{ color: '#9DA2B4' }} />
                                                    </IconButton>
                                                </div>
                                                {!isDefault ? <IconButton
                                                    style={{ height: 40, width: 40 }}
                                                    onClick={() => {
                                                        setDeleteStage({ ...stage, stageId });
                                                        setEditStage(null);
                                                        setLoadingP(false)
                                                    }}
                                                >
                                                    <DeleteRounded style={{ color: '#D8DAE1' }} />
                                                </IconButton>
                                                    : <IconButton style={{ height: 40, width: 40 }}></IconButton>}
                                            </div>
                                        </div>
                                    }
                                </div>
                            );
                        })}
                        <Modal
                            style={{ display: "flex" }}
                            open={editStagesOpen}
                            disableAutoFocus={true}
                        >
                            <EditStage
                                data={editStage}
                                stageId={editStage && editStage.stageId}
                                handleClose={() => {
                                    setEditStagesOpen(false);
                                }}
                            />
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Stages;

function getSortedStageIdsAlphabetical(stages) {
    return Object.keys(stages)
        .filter(stageId => {
            return Object.keys(stages).includes(stageId);
        })
        .sort((a, b) => {
            if (stages[a].name > stages[b].name) {
                return 1;
            }
            if (stages[a].name < stages[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const stageA = { ...stages[a] };
            const stageB = { ...stages[b] };
            stageA.listPosition = stageA.listPosition ? stageA.listPosition : 0;
            stageB.listPosition = stageB.listPosition ? stageB.listPosition : 0;

            return stageA.listPosition - stageB.listPosition;
        });
}